import { getData, postData, patchData, deleteData } from ".";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { tokenAtom, alertAtom } from "../store";
import { DateTime } from "luxon";

export const useClientFiles = (clientId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["clientFiles", clientId],
    async () => {
      const { data: files } = await getData(
        `/api/clients/${clientId}/files`,
        token
      );
      return files;
    },
    { enabled: !!token && !!clientId }
  );
};

export const useUploadClientFile = (clientId) => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const queryClient = useQueryClient();
  const queryKey = ["clientFiles", clientId];

  return useMutation(
    (data) => postData(`/api/clients/${clientId}/files`, token, data, true),
    {
      enabled: !!token && !!clientId,
      onSuccess: ({ data: file }) => {
        const clientFiles = queryClient.getQueryData(queryKey);
        clientFiles.push(file);
        queryClient.setQueryData(queryKey, clientFiles);
      },
      onError: () => {
        setAlert({ content: "Error uploading file" });
      },
    }
  );
};

export const useRenameFile = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (file) => patchData(`/api/files/${file.id}/rename`, token, { data: file }),
    {
      enabled: !!token,
      onMutate: async (file) => {
        // optimistically update client files
        const queryKey = ["clientFiles", file.relationships.client.data.id];
        await queryClient.cancelQueries(queryKey);
        const currentClientFiles = queryClient.getQueryData(queryKey);
        const previousClientFiles = JSON.parse(
          JSON.stringify(currentClientFiles)
        );
        queryClient.setQueryData(queryKey, (clientFiles) => {
          const fileIndex = clientFiles.findIndex(
            (clientFile) => clientFile.id === file.id
          );
          clientFiles[fileIndex].attributes.name = file.attributes.name;
          clientFiles[fileIndex].attributes.updatedAt = DateTime.utc().toISO();
          return clientFiles;
        });
        return { previousClientFiles, queryKey };
      },
      onError: (error, _clientFiles, context) => {
        queryClient.setQueryData(context.queryKey, context.previousClientFiles);
        console.error("Error renaming file", error);
        setAlert({ content: "Error renaming file" });
      },
      onSettled: (_data, _error, _vars, context) => {
        // refetch client files after renaming a file
        queryClient.invalidateQueries(context.queryKey);
      },
    }
  );
};
