import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClientNavButtons = ({ client }) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => navigate(`/clients/${client.id}/ehr`)}
        sx={{ m: 2 }}
      >
        EHR
      </Button>

      <Button
        component="a"
        href={`https://cnschiro.com/files/client-forms/${client.id}`}
        target="cns-1"
        sx={{ m: 2 }}
      >
        Forms
      </Button>

      <Button
        onClick={() => navigate(`/clients/${client.id}/files`)}
        sx={{ m: 2 }}
      >
        Files
      </Button>

      <Button
        component="a"
        href={`https://cnschiro.com/communication/log/${client.id}`}
        target="cns-1"
        sx={{ m: 2 }}
      >
        Communication Log
      </Button>
    </>
  );
};
export default ClientNavButtons;
