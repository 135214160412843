import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useRenameFile } from "../../queries/files";

const FileRenameDialog = ({ file, onClose }) => {
  const { mutate: renameFile } = useRenameFile();
  const [fileName, setFileName] = useState(file?.attributes?.name ?? "");

  const handleRenameFile = () => {
    renameFile({ ...file, attributes: { ...file.attributes, name: fileName } });
    onClose();
  };

  useEffect(() => {
    if (file?.attributes.name) setFileName(file.attributes.name);
  }, [file?.attributes.name, setFileName]);

  return (
    <Dialog open={!!file} onClose={onClose}>
      <DialogTitle>Rename File</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New File Name"
          type="text"
          fullWidth
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRenameFile} color="primary">
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileRenameDialog;
