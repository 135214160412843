import { useCallback, useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Sync, CloudDone, Edit, Warning } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import Touchpad from "./Touchpad";
import Loading from "../Loading";
import {
  useVisit,
  useUpdateVisit,
  useCompleteVisit,
} from "../../queries/visits";
import { useParams } from "react-router-dom";
import { useAllChiropractors } from "../../queries/chiropractors";
import { useAtomValue } from "jotai";
import { permissionsAtom } from "../../store";
import { useUserChiropractor } from "../../queries/chiropractors";
import { useUser } from "../../queries/users";
import { useVisitNoteHistory } from "./useVisitNoteHistory";

export default function Visit() {
  const { visitId } = useParams();

  const { data: visit, isLoading: visitLoading } = useVisit(visitId);
  const { data: chiropractors } = useAllChiropractors();
  const { data: user } = useUser();
  const { data: userChiropractor } = useUserChiropractor();
  const { mutate: updateVisit, isLoading: visitUpdating } = useUpdateVisit();
  const { mutate: completeVisit, isLoading: visitCompleting } =
    useCompleteVisit(visit?.relationships?.location?.data?.id);
  const permissions = useAtomValue(permissionsAtom);
  const [draftVisit, setDraftVisit] = useState(null);
  const noteRef = useRef();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset: resetForm,
    setValue,
    watch,
  } = useForm({
    defaultValues: { visitNote: visit?.attributes?.visitNote || "" },
  });

  const visitNote = watch("visitNote");

  const setNote = (newNote) => {
    setValue("visitNote", newNote, { shouldDirty: true });
  };

  const {
    undo,
    redo,
    canUndo,
    canRedo,
    reset: resetHistory,
  } = useVisitNoteHistory({
    setNote: setNote,
    currentNote: visitNote,
    initialNote: visit?.attributes?.visitNote || "",
  });

  const editAllowed = useMemo(() => {
    return (
      (permissions?.includes("ehr_edit_visit") &&
        visit?.relationships?.chiropractor?.data?.id ===
          userChiropractor?.id) ||
      permissions?.includes("ehr_edit_any_visit") ||
      user?.attributes?.isSuper
    );
  }, [permissions, visit, userChiropractor, user]);

  const onSubmit = (formData, complete) => {
    const newVisit = {
      ...visit,
      attributes: { ...visit.attributes, ...formData },
    };

    try {
      updateVisit(newVisit, {
        onSuccess: () => {
          resetForm({ visitNote: formData.visitNote }, { keepValues: true });
        },
      });
      if (complete) {
        completeVisit(newVisit.id);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSwitchVisit = () => {
    resetForm({ visitNote: visit.attributes.visitNote });
    resetHistory(visit.attributes.visitNote);

    // enable editing by default if visit is not completed
    enableEditing(!visit.attributes.timeCompleted);
  };

  const enableEditing = (enable) => {
    if (enable) {
      const newDraft = _.cloneDeep(visit);
      setDraftVisit(newDraft);
    } else {
      setDraftVisit(null);
    }
  };

  // save visit after 1 second delay
  const delayedSaveVisit = useCallback(
    _.debounce(() => {
      handleSubmit(onSubmit)(false);
    }, 1000),
    [draftVisit?.id]
  );

  useEffect(() => {
    if (isDirty) delayedSaveVisit();
  }, [delayedSaveVisit, visitNote]);

  // Cleanup the debounce function on unmount
  useEffect(() => {
    return () => {
      delayedSaveVisit.cancel();
    };
  }, [delayedSaveVisit]);

  useEffect(() => {
    if (visit?.id) handleSwitchVisit();
  }, [visit?.id]);

  // set cursor position if editing enabled
  useEffect(() => {
    if (!!draftVisit?.id) {
      noteRef.current?.focus();
      noteRef.current?.setSelectionRange(
        draftVisit?.attributes?.visitNote?.length || 0,
        draftVisit?.attributes?.visitNote?.length || 0
      );
    }
  }, [draftVisit?.id]);

  if (!visit || visitLoading || visitCompleting) return <Loading size={80} />;

  return (
    <>
      <Box flex="0 0 auto" sx={{ p: 1, textAlign: "center" }}>
        {visit?.attributes.appointmentTime
          ? new Date(visit.attributes.appointmentTime).toLocaleString(
              {},
              {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              }
            ) +
            " @ " +
            new Date(visit.attributes.appointmentTime)
              .toLocaleString(
                {},
                { hour: "numeric", minute: "2-digit", timeZone: "UTC" }
              )
              .toLowerCase()
          : "Walk-in"}
        {" for "}
        {chiropractors?.find(
          (chiro) =>
            Number(chiro.id) ===
            Number(visit?.relationships?.chiropractor?.data?.id)
        )?.attributes.displayName || "Any Chiropractor"}
      </Box>
      <Box alignItems="center" sx={{ width: "100%", px: 1 }}>
        <form onSubmit={() => handleSubmit(onSubmit)(false)}>
          <Controller
            name="visitNote"
            control={control}
            rules={{ required: "Visit note cannot be empty" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                rows={5}
                style={{ width: "100%" }}
                inputRef={noteRef}
                variant="outlined"
                disabled={!draftVisit}
                error={!!error}
                helperText={
                  error ? (
                    <>
                      <Warning fontSize="inherit" /> {error.message}
                    </>
                  ) : visitUpdating ? (
                    <>
                      <Sync fontSize="inherit" /> Saving...
                    </>
                  ) : isDirty ? (
                    <>
                      <Edit fontSize="inherit" /> Editing...
                    </>
                  ) : (
                    <>
                      <CloudDone fontSize="inherit" /> All changes saved. :-)
                    </>
                  )
                }
                onBlur={() => {
                  setTimeout(() => {
                    if (
                      noteRef?.current &&
                      document.activeElement !== noteRef.current
                    ) {
                      noteRef.current.setSelectionRange(
                        noteRef.current.value.length,
                        noteRef.current.value.length
                      );
                    }
                  }, 100);
                }}
              />
            )}
          />
        </form>
      </Box>
      <Box flex="0 1 100%" sx={{ p: 1, overflowY: "hidden" }}>
        {draftVisit ? (
          <Touchpad
            noteRef={noteRef}
            addNote={setNote}
            undo={undo}
            redo={redo}
            canUndo={canUndo}
            canRedo={canRedo}
            completeVisit={
              visit.attributes.timeCompleted
                ? false
                : () => handleSubmit(onSubmit)(true)
            }
          />
        ) : (
          <>
            <Typography variant="h6" align="center">
              Editing Disabled
            </Typography>
            <Typography align="center">
              This visit is already completed.
              <br />
              {editAllowed && (
                <Button align="center" onClick={() => enableEditing(true)}>
                  Edit Visit
                </Button>
              )}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}
