import { useState, useRef, memo } from "react";
import { styled } from "@mui/material/styles";
import { Box, ButtonBase, Menu, MenuItem } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const TouchpadButton = ({
  children,
  size,
  buttonType,
  options,
  disabled,
  onClick,
  onOptionClick,
}) => {
  const CustomButton = styled(ButtonBase)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: 4,
    width: "100%",
    height: 0,
    padding: "50% 0",
    flexDirection: "column",
    "& svg": {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
    "&.touchpad": {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.accent.contrastText,
    },
    "&.function": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:disabled": {
      opacity: 0.25,
    },
  }));

  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);
  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const minSwipeDistance = 10;

  const handleTouchStart = (e) => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientY;
  };

  const handleTouchMove = (e) => {
    return (touchEnd.current = e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchEnd.current - touchStart.current;

    if (distance > minSwipeDistance) {
      setMenuOpen(true);
    }
  };

  const handleRightClick = (e) => {
    setMenuOpen(true);
    e.preventDefault();
  };

  return (
    <Box style={{ width: size, padding: size / 20 }} ref={buttonRef}>
      <CustomButton
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={(e) => handleRightClick(e)}
        //onClick={() => setTimeout(() => onClick(), 200)} // delay onClick event to show ripple before re-rendering
        onClick={onClick}
        className={buttonType}
        style={{ fontSize: size / 5.5 }}
        disabled={disabled}
      >
        {children}
        {options?.length > 0 && (
          <ExpandMore style={{ position: "absolute", right: 4, top: 4 }} />
        )}
      </CustomButton>
      <Menu
        open={menuOpen && options?.length > 0}
        anchorEl={buttonRef.current}
        onClose={() => setMenuOpen(false)}
        onContextMenu={(e) => e.preventDefault()}
        disableEnforceFocus={true}
      >
        {options?.map((option, j) => (
          <MenuItem
            key={"option-" + j}
            style={{ fontSize: size / 5.5 }}
            onClick={(e) => {
              onOptionClick(option);
              setMenuOpen(false);
            }}
          >
            {option.caption}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const MemoizedButton = memo(TouchpadButton);
export default MemoizedButton;
