import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const tokenAtom = atom(null);
export const currentLocationAtom = atomWithStorage("currentLocation", null);
export const onlyMeAtom = atom(false);
export const hideCalledAtom = atom(false);
export const alertAtom = atom(null);
export const errorAtom = atom(null);
export const waitingForMindbodyAtom = atom(false);
export const currentClientAtom = atom(null);
export const permissionsAtom = atom([]);
