import { BrowserRouter, Routes, Route } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";
import KioskLayout from "./layouts/KioskLayout";
import Dashboard from "./components/Dashboard";
import Ehr from "./components/ehr/Ehr";
import Waitlist from "./components/ehr/Waitlist";
import ClientProfile from "./components/clients/ClientProfile";
import ClientFiles from "./components/clients/ClientFiles";
import Settings from "./components/settings/Settings";
import Kiosk from "./components/kiosk";
import Login from "./components/login";
import Loading from "./components/Loading";
import { useRefreshToken } from "./queries/auth";
import { useUser } from "./queries/users";
import {
  useUserChiropractor,
  usePublicChiropractors,
} from "./queries/chiropractors";
import { useAppSettings } from "./queries/settings";
import { usePublicLocations } from "./queries/locations";
import { useAtomValue } from "jotai";
import { currentLocationAtom } from "./store";
import Schedule from "./components/schedule/Schedule";
import Reports from "./components/reports";

function App() {
  const currentLocation = useAtomValue(currentLocationAtom);
  const { data: user, loading: userLoading } = useUser();
  const { loading: userChiropractorLoading } = useUserChiropractor();

  // try logging in with refreshToken cookie
  const { loading: tokenLoading } = useRefreshToken();

  // silently cache select data from API using react-query (for speed)
  useAppSettings();
  usePublicLocations();
  usePublicChiropractors();

  // show loading page if still trying to log in
  if (tokenLoading || userLoading || userChiropractorLoading)
    return <Loading />;

  // show login flow if user hasn't logged in or hasn't
  if (!user || !currentLocation) return <Login />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="waitlist" element={<Waitlist />} />
          <Route path="visit/:visitId" element={<Ehr />} />
          <Route path="clients/:clientId" element={<ClientProfile />} />
          <Route path="clients/:clientId/ehr" element={<Ehr />} />
          <Route path="clients/:clientId/files" element={<ClientFiles />} />
          <Route path="reports" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="schedule" element={<Schedule />} />
        </Route>
        <Route path="/kiosk" element={<KioskLayout />}>
          <Route index element={<Kiosk />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
