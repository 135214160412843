import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSetAtom } from "jotai";
import { useClient } from "../../queries/clients";
import FileUpload from "./FileUpload";
import FileList from "./FileList";
import FileRenameDialog from "./FileRenameDialog";
import Loading from "../Loading";
import { currentClientAtom } from "../../store";

const ClientFiles = () => {
  const { clientId } = useParams();

  const { data: client, isLoading: clientLoading } = useClient(clientId);
  const [selectedFile, setSelectedFile] = useState(null);

  const setCurrentClient = useSetAtom(currentClientAtom);
  useEffect(() => {
    if (!!client) {
      setCurrentClient(client);
    }
    return () => {
      setCurrentClient(null);
    };
  }, [client, setCurrentClient]);

  if (clientLoading) return <Loading size={64} caption="Loading client..." />;

  return (
    <div>
      <Typography variant="h5" align="center" sx={{ m: 2 }}>
        {client.attributes.firstName} {client.attributes.lastName}
      </Typography>
      <FileUpload clientId={clientId} />
      <FileList clientId={clientId} onRename={setSelectedFile} />
      <FileRenameDialog
        file={selectedFile}
        onClose={() => setSelectedFile(null)}
      />
    </div>
  );
};

export default ClientFiles;
