import axios from "axios";

const sendDataToApi = async (
  method,
  path,
  token,
  data = null,
  isFile = false
) => {
  const axiosConfig = {
    method,
    url: path,
    withCredentials: true,
    headers: {
      "Content-Type": isFile
        ? "multipart/form-data"
        : "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    data,
  };
  if (token) axiosConfig.headers.Authorization = `Bearer ${token}`;

  const response = await axios(axiosConfig);
  if (response.status === 204) {
    return {};
  }
  return response.data;
};

export const getData = (path, token) => sendDataToApi("get", path, token);
export const postData = (path, token, data) =>
  sendDataToApi("post", path, token, data);
export const patchData = (path, token, data, isFile = false) =>
  sendDataToApi("patch", path, token, data, isFile);
export const deleteData = (path, token) => sendDataToApi("delete", path, token);

export const generateQueryString = (options) => {
  let queryString = "?";

  if (options.filter) {
    for (const [key, value] of Object.entries(options.filter)) {
      queryString += `filter[${key}]=${encodeURIComponent(value)}&`;
    }
  }

  if (options.sort) {
    queryString += `sort=${encodeURIComponent(options.sort)}&`;
  }

  if (options.page) {
    for (const [key, value] of Object.entries(options.page)) {
      queryString += `page[${key}]=${encodeURIComponent(value)}&`;
    }
  }

  if (options.include) {
    queryString += `include=${encodeURIComponent(options.include)}&`;
  }

  if (options.fields) {
    for (const [key, value] of Object.entries(options.fields)) {
      queryString += `fields[${key}]=${encodeURIComponent(value)}&`;
    }
  }

  return queryString.slice(0, -1); // Remove trailing '&'
};
