import { useEffect } from "react";
import { Avatar, Container, Paper, Typography } from "@mui/material";
import { useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import { useClient } from "../../queries/clients";
import { currentClientAtom } from "../../store";
import Loading from "../Loading";
import ClientNavButtons from "./ClientNavButtons";
import ClientForm from "./ClientForm";

export default () => {
  const params = useParams();
  const {
    isLoading: clientLoading,
    isError,
    error,
    data: client,
  } = useClient(params.clientId);

  const setCurrentClient = useSetAtom(currentClientAtom);
  useEffect(() => {
    if (!!client) {
      setCurrentClient(client);
    }
    return () => {
      setCurrentClient(null);
    };
  }, [client, setCurrentClient]);

  if (clientLoading) {
    return <Loading caption="Loading..." />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  if (!client) return <div>Client file not found.</div>;

  return (
    <Container maxWidth={false} style={{ textAlign: "center", padding: 16 }}>
      <ClientNavButtons client={client} />

      <Typography variant="h6">
        {client.attributes.firstName?.trim() +
          " " +
          (client.attributes.nickname?.trim() &&
          client.attributes.nickname?.trim() !== client.attributes.firstName
            ? '"' + client.attributes.nickname?.trim() + '"'
            : "") +
          " " +
          client.attributes.lastName}
      </Typography>
      <Avatar
        src={client?.attributes.photoUrl ?? "//:0"}
        style={{
          width: 128,
          height: 128,
          cursor: "pointer",
          margin: "auto",
        }}
      >
        {(client?.attributes.nickname?.charAt(0) ||
          client?.attributes.firstName?.charAt(0)) +
          client?.attributes.lastName?.charAt(0)}
      </Avatar>

      <Paper sx={{ p: 2, mt: 2, textAlign: "left" }}>
        <Typography variant="h6" gutterBottom>
          Profile Information
        </Typography>

        <ClientForm client={client} />
      </Paper>
    </Container>
  );
};
