import { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { useUpdateClient, useCreateClient } from "../../queries/clients";

const DATE_FORMATS = ["M/d/yyyy", "MM/dd/yyyy", "M-d-yyyy", "MM-dd-yyyy"];
const PHONE_PATTERN = /^\D*(\d\D*){10}$/;

const formatPhoneNumber = (phone) => {
  const digits = phone?.replace(/\D+/g, "") ?? "";
  if (digits.length === 10) {
    // Format phone number as XXX-XXX-XXXX
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else {
    // Return only digits if not full phone number
    return digits;
  }
};

const ClientForm = ({ client }) => {
  const birthDT = DateTime.fromISO(client?.attributes.birthDate);

  const clientFields = {
    firstName: client?.attributes.firstName ?? "",
    lastName: client?.attributes.lastName ?? "",
    nickname: client?.attributes.nickname ?? "",
    gender: client?.attributes.gender ?? "",
    birthDate: birthDT.isValid ? birthDT.toFormat("MM/dd/yyyy") : "",
    email: client?.attributes.email ?? "",
    mobilePhone: formatPhoneNumber(client?.attributes.mobilePhone),
    homePhone: formatPhoneNumber(client?.attributes.homePhone),
    workPhone: formatPhoneNumber(client?.attributes.workPhone),
    streetAddress1: client?.attributes.streetAddress1 ?? "",
    streetAddress2: client?.attributes.streetAddress2 ?? "",
    city: client?.attributes.city ?? "",
    state: client?.attributes.state ?? "",
    zipCode: client?.attributes.zipCode ?? "",
    sendAccountEmails: !!client?.attributes.sendAccountEmails,
    sendAccountTexts: !!client?.attributes.sendAccountTexts,
    sendScheduleEmails: !!client?.attributes.sendScheduleEmails,
    sendScheduleTexts: !!client?.attributes.sendScheduleTexts,
    sendPromoEmails: !!client?.attributes.sendPromoEmails,
    sendPromoTexts: !!client?.attributes.sendPromoTexts,
    photoUrl: client?.attributes.photoUrl,
    active: !!client?.attributes.active,
  };

  if (client?.attributes.mboId) clientFields.mboId = client.attributes.mboId;
  if (client?.attributes.barcode)
    clientFields.barcode = client.attributes.barcode;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: clientFields,
  });

  const { mutate: updateClient } = useUpdateClient();
  const { mutate: createClient } = useCreateClient();

  const parseDateToISO = (dateString) => {
    for (let format of DATE_FORMATS) {
      const dt = DateTime.fromFormat(dateString.trim(), format);
      if (dt.isValid) {
        return dt.toISODate();
      }
    }
    return null; // Return null if no valid date is found
  };

  const validateDate = (dateString) => {
    for (let format of DATE_FORMATS) {
      const dt = DateTime.fromFormat(dateString.trim(), format);
      if (dt.isValid) {
        return dt;
      }
    }

    return "Invalid date format";
  };

  const validateEmail = (email) => {
    if (email === "") {
      // Allow blank email
      return true;
    }
    // Regular expression for validating email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email) ? true : "Invalid email address";
  };

  const onSubmit = (formData) => {
    // clean/normalize client data
    const clientData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (typeof formData[key] === "string") {
          clientData[key] = formData[key].trim();
        } else {
          clientData[key] = formData[key];
        }
      }
    }
    clientData.birthDate = parseDateToISO(clientData.birthDate);
    clientData.mobilePhone = clientData.mobilePhone.replace(/\D+/g, "");
    clientData.homePhone = clientData.homePhone.replace(/\D+/g, "");
    clientData.workPhone = clientData.workPhone.replace(/\D+/g, "");

    if (!!client?.id) {
      updateClient({ id: client.id, type: "clients", attributes: clientData });
    } else {
      createClient({ type: "clients", attributes: clientData });
    }
  };

  useEffect(() => {
    reset(clientFields);
  }, [client, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={2}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name *"
                variant="outlined"
                error={!!errors.firstName}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.firstName?.message ?? ""}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name *"
                variant="outlined"
                error={!!errors.lastName}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.lastName?.message ?? ""}
              />
            )}
          />
          <Controller
            name="nickname"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Preferred Name"
                variant="outlined"
                error={!!errors.nickname}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.firstName?.message ?? ""}
              />
            )}
          />
          <Controller
            name="birthDate"
            control={control}
            rules={{
              required: "Date of birth is required",
              validate: validateDate,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                fullWidth
                label="Date of Birth *"
                error={!!errors.birthDate}
                helperText={errors.birthDate?.message ?? ""}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Gender"
                variant="outlined"
                error={!!errors.gender}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.gender?.message ?? ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Controller
            name="email"
            control={control}
            rules={{ validate: validateEmail }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                error={!!errors.email}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.email?.message ?? ""}
              />
            )}
          />
          <Controller
            name="mobilePhone"
            control={control}
            rules={{
              pattern: {
                value: PHONE_PATTERN,
                message: "Invalid phone number. Must be 10 digits.",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Mobile Phone"
                variant="outlined"
                error={!!errors.mobilePhone}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.mobilePhone?.message ?? ""}
              />
            )}
          />
          <Controller
            name="homePhone"
            control={control}
            rules={{
              pattern: {
                value: PHONE_PATTERN,
                message: "Invalid phone number. Must be 10 digits.",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Home Phone"
                variant="outlined"
                error={!!errors.homePhone}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.homePhone?.message ?? ""}
              />
            )}
          />
          <Controller
            name="workPhone"
            control={control}
            rules={{
              pattern: {
                value: PHONE_PATTERN,
                message: "Invalid phone number. Must be 10 digits.",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Work Phone"
                variant="outlined"
                error={!!errors.workPhone}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.workPhone?.message ?? ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <Controller
            name="streetAddress1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Street Address (Line 1)"
                variant="outlined"
                error={!!errors.streetAddress1}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.streetAddress1?.message ?? ""}
              />
            )}
          />
          <Controller
            name="streetAddress2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Street Address (Line 2)"
                variant="outlined"
                error={!!errors.streetAddress2}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.streetAddress2?.message ?? ""}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="City"
                variant="outlined"
                error={!!errors.city}
                fullWidth
                sx={{ m: 1 }}
                helperText={errors.city?.message ?? ""}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    variant="outlined"
                    error={!!errors.state}
                    fullWidth
                    sx={{ m: 1 }}
                    helperText={errors.state?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Zip Code"
                    variant="outlined"
                    error={!!errors.zipCode}
                    fullWidth
                    sx={{ m: 1 }}
                    helperText={errors.zipCode?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <FormControl component="fieldset" fullWidth sx={{ mt: 1, ml: 1 }}>
            <FormLabel component="legend">Account Notifications</FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendAccountEmails"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Emails"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendAccountTexts"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Texts"
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl component="fieldset" fullWidth sx={{ mt: 1, ml: 1 }}>
            <FormLabel component="legend">Schedule Notifications</FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendScheduleEmails"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Emails"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendScheduleTexts"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Texts"
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl component="fieldset" fullWidth sx={{ mt: 1, ml: 1 }}>
            <FormLabel component="legend">News & Promo Notifications</FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendPromoEmails"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Emails"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="sendPromoTexts"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Texts"
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <FormControl component="fieldset" fullWidth sx={{ mt: 1, ml: 1 }}>
            <FormLabel component="legend">Account Status</FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <FormControlLabel
                  control={
                    <Controller
                      name="active"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
          </FormControl>
          {clientFields?.barcode && (
            <Controller
              name="barcode"
              control={control}
              rules={{ required: "Barcode is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Barcode *"
                  variant="outlined"
                  error={!!errors.barcode}
                  fullWidth
                  sx={{ m: 1 }}
                  helperText={errors.barcode?.message ?? ""}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "center" }}>
        <Button
          type="submit"
          variant="contained"
          sx={{ m: 2 }}
          disabled={!isDirty}
        >
          Save Changes
        </Button>
      </Box>
    </form>
  );
};
export default ClientForm;
