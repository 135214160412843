import { useState } from "react";
import { useUploadClientFile } from "../../queries/files";
import { Box, Button, LinearProgress, Snackbar, Alert } from "@mui/material";

function FileUpload({ clientId }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const { mutate: uploadFile, isLoading: isUploading } =
    useUploadClientFile(clientId);

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await uploadFile(formData, {
        onSuccess: () => {
          setSnackbarMessage("File uploaded successfully");
          setSnackbarSeverity("success");
        },
        onError: (error) => {
          console.error("Error uploading file:", error);
          setSnackbarMessage("Error uploading file");
          setSnackbarSeverity("error");
        },
        onSettled: () => {
          setOpenSnackbar(true);
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Button variant="contained" component="label" sx={{ m: 2 }}>
        Upload New File
        <input
          type="file"
          hidden
          onChange={(e) => handleUpload(e.target.files[0])}
        />
      </Button>
      <div>
        <LinearProgress
          sx={{ visibility: isUploading ? "visible" : "hidden" }}
        />
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FileUpload;
