import { useCallback, useEffect, useState, useRef } from "react";
import _ from "lodash";

export function useVisitNoteHistory({
  setNote,
  currentNote,
  initialNote = "",
}) {
  const lastNoteRef = useRef(initialNote);
  const historyRef = useRef({
    list: [initialNote],
    index: 0,
  });
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const isUndoRedoAction = useRef(false);

  // Debounced function to update history
  const updateHistory = useCallback(
    _.debounce((newNote) => {
      if (!isUndoRedoAction.current && lastNoteRef.current !== newNote) {
        const newHistory = { ...historyRef.current };
        newHistory.list = newHistory.list.slice(0, newHistory.index + 1);
        newHistory.list.push(newNote);
        newHistory.index += 1;
        historyRef.current = newHistory;
        lastNoteRef.current = newNote;
        updateCanUndoRedo();
      }
      isUndoRedoAction.current = false;
    }, 250),
    []
  );

  const updateCanUndoRedo = () => {
    setCanUndo(historyRef.current.index > 0);
    setCanRedo(historyRef.current.index < historyRef.current.list.length - 1);
  };

  useEffect(() => {
    updateHistory(currentNote);
  }, [currentNote, updateHistory]);

  const reset = useCallback((newInitialNote = "") => {
    historyRef.current = {
      list: [newInitialNote],
      index: 0,
    };
    lastNoteRef.current = newInitialNote;
    updateCanUndoRedo();
  }, []);

  const undo = useCallback(() => {
    isUndoRedoAction.current = true;
    const history = historyRef.current;
    const newIndex = Math.max(history.index - 1, 0);
    const newNote = history.list[newIndex];
    setNote(newNote);
    historyRef.current = { ...history, index: newIndex };
    updateCanUndoRedo();
  }, [setNote]);

  const redo = useCallback(() => {
    isUndoRedoAction.current = true;
    const history = historyRef.current;
    const newIndex = Math.min(history.index + 1, history.list.length - 1);
    const newNote = history.list[newIndex];
    setNote(newNote);
    historyRef.current = { ...history, index: newIndex };
    updateCanUndoRedo();
  }, [setNote]);

  return { undo, redo, canUndo, canRedo, reset };
}
