import { getData, postData, patchData } from ".";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { tokenAtom, alertAtom, waitingForMindbodyAtom } from "../store";
import { useNavigate } from "react-router-dom";

export const useClient = (clientId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["clients", clientId],
    async () => {
      const { data: clients } = await getData(
        `/api/clients/${clientId}`,
        token
      );
      return clients;
    },
    { enabled: !!token && !!clientId }
  );
};

export const useKioskClients = ({ phone, barcode }) => {
  const token = useAtomValue(tokenAtom);

  const filterString = barcode ? `[barcode]=${barcode}` : `[phone]=${phone}`;

  return useQuery(
    ["kioskClients", { phone, barcode }],
    async () => {
      const { data: clients } = await getData(
        `/api/clients?filter${filterString}&include=children,spouse,members`,
        token
      );
      return clients;
    },
    { enabled: !!token && (!!phone || !!barcode) }
  );
};

export const useClientSearch = (searchTerm) => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["clients", "search", { searchTerm }],
    async () => {
      if (searchTerm.trim().length < 2) return [];
      const { data: clients } = await getData(
        `/api/clients?filter[search]=${searchTerm}`,
        token
      );
      return clients;
    },
    {
      enabled: !!token && searchTerm?.length > 1,
      keepPreviousData: true,
      onSuccess: (clients) => {
        clients.forEach((client) => {
          queryClient.setQueryData(["clients", client.id], client);
        });
      },
    }
  );
};

export const useRefreshClients = () => {
  const token = useAtomValue(tokenAtom);

  return useMutation(
    () => {
      return postData(`/api/clients/refresh`, token);
    },
    {
      enabled: !!token,
      onSuccess: async (data) => {
        if (data.success) {
          console.log("Clients refreshed at " + new Date());
        } else {
          console.log("Clients NOT refreshed at " + new Date());
        }
      },
    }
  );
};

export const useUpdateClient = () => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const setWaitingForMindbody = useSetAtom(waitingForMindbodyAtom);
  const queryClient = useQueryClient();

  return useMutation(
    (client) => patchData(`/api/clients/${client.id}`, token, { data: client }),
    {
      enabled: !!token,
      onMutate: async (client) => {
        setWaitingForMindbody(true);

        // optimistically update client
        const queryKey = ["clients", client?.id];
        await queryClient.cancelQueries(queryKey);
        const previousClient = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, client);

        return { previousClient };
      },
      onSettled: () => setWaitingForMindbody(false),
      onError: (error, _client, context) => {
        console.error(error);
        queryClient.setQueryData(
          ["clients", context.previousClient?.id],
          context.previousClient
        );
        setAlert({ content: "Error updating client" });
      },
      onSuccess: async ({ data: updatedClient }) => {
        const clientKey = ["clients", updatedClient.id];
        await queryClient.cancelQueries(clientKey);
        queryClient.setQueryData(clientKey, updatedClient);
      },
    }
  );
};

export const useCreateClient = () => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (client) => postData(`/api/clients`, token, { data: client }),
    {
      enabled: !!token,
      onError: (error) => {
        console.error(error);
        setAlert({ content: "Error creating client" });
      },
      onSuccess: async ({ data: client }) => {
        const clientKey = ["clients", client.id];
        await queryClient.cancelQueries(clientKey);
        queryClient.setQueryData(clientKey, client);
        navigate(`/clients/${client.id}`);
      },
    }
  );
};
